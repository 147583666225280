export default async function fetchJson(input, init) {
  const response = await fetch(input, init);

  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line
  const text = await response.text(); // Parse it as text
  let data = "";
  try {
    data = JSON.parse(text); // Try to parse it as JSON
  } catch (error) {
    data = text; // Not valid JSON return the text
  }
  const headers = response.headers;

  // response.ok is true when res.status is 2xx
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (response.ok) {
    return { data, headers, response };
  }

  throw new FetchError({
    message: response.statusText,
    response,
    data,
  });
}

export class FetchError extends Error {
  constructor({ message, response, data }) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
    this.response = response;
    this.data = data ?? { message };

    const bugSnagMetadata = [
      {
        label: "response",
        fields: {
          url: response.url,
          headers: response.headers,
          statusText: response.statusText,
          status: response.status,
        },
      },
    ];
  }
}

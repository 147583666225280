/**
 * Module define all API paths
 */
import { parseCookies } from 'nookies';
import { parseURLParam } from '../utils/ParseValues';
import { createUUID } from '../utils/UUID';

const isServer = () => typeof window === 'undefined';

const BASE_URL = process.env.HOST
  ? process.env.HOST
  : process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
    ? '/api/'
    : 'http://localhost:8000/api/';

const TOKEN = 'realestateagents.token';

export function generateApiUrl(url) {
  return BASE_URL + url;
}
const KEY = '9bapp6u7yTkfEPQU';

/**
 * Function to prep the URL for querystring / url
 * @param { String } url -- the URL we want to manipulate
 * @param { Object } params -- params for querystring
 */
const prepURL = (url, params) => {
  const {
    city,
    state,
    zip,
    neighborhood,
    page,
    limit,
    startDate,
    endDate,
    altosAgentId,
    maxPrice,
    minPrice,
    maxBeds,
    minBeds,
    maxBaths,
    minBaths,
    bounds,
    selectedBrokerages,
    coordinate,
    years,
    city_slug,
    search,
    residenceType,
    selectedNeighborhoods,
  } = params;
  if (zip) {
    url += `location[zip]=${zip}&`;
  } else {
    if (neighborhood) {
      url += `location[nbhd]=${neighborhood}&`;
    }

    if (city) {
      url += `location[city]=${parseURLParam(city)
        .toUpperCase()
        .replace(' ', '%20')}&`;
    }

    if (state) {
      url += `location[state]=${state.toUpperCase()}&`;
    }
  }

  if (page) {
    url += `page[number]=${page}&`;
  }

  if (limit) {
    url += `page[limit]=${limit}&`;
  }

  if (startDate) {
    url += `date[start]=${startDate}&`;
  }

  if (endDate) {
    url += `date[end]=${endDate}&`;
  }

  if (altosAgentId) {
    url += `altos_agent_id=${altosAgentId}&`;
  }

  if (maxPrice) {
    url += `price[max]=${maxPrice}&`;
  }

  if (minPrice) {
    url += `price[min]=${minPrice}&`;
  }
  if (minBeds) {
    url += `beds[min]=${minBeds}&`;
  }
  if (minBaths) {
    url += `baths[min]=${minBaths}&`;
  }
  if (maxBeds) {
    url += `beds[max]=${maxBeds}&`;
  }
  if (maxBaths) {
    url += `baths[max]=${maxBaths}&`;
  }

  if (bounds) {
    if (bounds.sw) {
      url += `map[lat_min]=${bounds.sw.lat}&map[lon_min]=${bounds.sw.lng}&`;
    }
    if (bounds.ne) {
      url += `map[lat_max]=${bounds.ne.lat}&map[lon_max]=${bounds.ne.lng}&`;
    }
  }

  if (coordinate) {
    if (coordinate.lat) {
      url += `map[lat]=${coordinate.lat}&`;
    }
    if (coordinate.lng) {
      url += `map[lon]=${coordinate.lng}&`;
    }
  }

  if (years) {
    if (years.length > 0) {
      let yearsFilter = '';
      for (let i = 0; i < years.length; i++) {
        yearsFilter += years[i];
        if (i < years.length - 1) {
          yearsFilter += ',';
        }
      }
      url += `year=${yearsFilter}&`;
    }
  }

  if (residenceType) {
    if (residenceType.length === 1) {
      url += `residence_type=${residenceType[0].value}&`;
    }
  }

  if (selectedBrokerages && selectedBrokerages.length > 0) {
    for (let i = 0; i < selectedBrokerages.length; i++) {
      url += `brokerage[]=${selectedBrokerages[i]}&`;
    }
  }

  if (selectedNeighborhoods && selectedNeighborhoods.length > 0) {
    for (let i = 0; i < selectedNeighborhoods.length; i++) {
      url += `nbhd[]=${selectedNeighborhoods[i].neighborhood}&`;
    }
  }

  if (search) {
    url += `search=${search}&`;
  }

  if (city_slug) {
    url += `city_slug=${city_slug}&`;
  }

  return url;
};

const setupRequestHeaders = (noContentType, ctx = null) => {
  let token = null;
  let headers = {
    'Content-Type': 'application/json',
  };
  if (!isServer()) {
    token = window.localStorage.getItem(TOKEN);
  }

  const storage = createUUID({ isServer: isServer() });

  if (storage?.lead_uuid) {
    headers.lead_uuid = storage.lead_uuid;
  }

  headers.uuid = storage?.session_uuid;

  if (noContentType) {
    headers = {};
  }

  if (token) {
    headers.Authorization = `Token ${token}`;
  }

  if (storage?.session_uuid) {
    headers['session-uuid'] = storage.session_uuid;
  }

  return headers;
};

const API = {
  getLocationMostRecentStats: ({
    city,
    state,
    zip,
    neighborhood,
    page,
    limit,
  }) => {
    const base = BASE_URL;
    const url = `${base}stats/most_recent/?secret=${KEY}&`;
    return prepURL(url, {
      city, state, zip, neighborhood, page, limit,
    });
  },

  getLocationMAI: ({
    city, state, zip, neighborhood, startDate,
  }) => {
    const base = BASE_URL;

    const url = `${base}stats/market_index/?`;

    return prepURL(url, {
      city, state, zip, neighborhood, startDate,
    });
  },

  getLocationExists: ({ city_slug }) => {
    const base = BASE_URL;
    const url = `${base}geo/location_exists/?city_slug=${city_slug}`;
    return url;
  },

  postAnalytics: () => {
    const url = `${BASE_URL}insights/`;
    return url;
  },

  postAgentReview: ({}) => {
    const url = `${BASE_URL}agents/review`;
    return url;
  },

  getRedirection: () => {
    const url = `${BASE_URL}redirection?secret=${KEY}`;
    return url;
  },

  PAGE_VIEW: ({}) => {
    const base = BASE_URL;
    const url = `${base}insights/page_view/`;
    return url;
  },

  getLocationTimelineStats: ({
    city,
    state,
    zip,
    neighborhood,
    page,
    limit,
    startDate,
  }) => {
    const base = BASE_URL;

    const url = `${base}stats/?`;
    return prepURL(url, {
      city,
      state,
      zip,
      neighborhood,
      page,
      limit,
      startDate,
    });
  },

  getLocationStats: ({
    city,
    state,
    zip,
    neighborhood,
    page,
    limit,
    startDate,
  }) => {
    const url = `${BASE_URL}stats/?`;
    return prepURL(url, {
      city,
      state,
      zip,
      neighborhood,
      page,
      limit,
      startDate,
    });
  },

  SITEMAP: ({
    state, city, zip, neighborhood, locales, page, limit,
  }) => {
    const url = `${BASE_URL}sitemap/?secret=${KEY}&`;
    let preppedURL = prepURL(url, {
      city,
      state,
      zip,
      neighborhood,
      page,
      limit,
    });

    preppedURL += `&locales=${locales}`;

    return preppedURL;
  },

  SEARCH_LOCATION: ({ location }) => {
    let url = `${BASE_URL}geo/location_search/?`;
    url += `location=${location}`;
    return url;
  },

  getLocationNearby: ({
    city,
    state,
    zip,
    neighborhood,
    page,
    limit,
    nearby,
    detailed,
  }) => {
    const url = `${BASE_URL}geo/nearby?`;
    let preppedURL = prepURL(url, {
      city,
      state,
      zip,
      neighborhood,
      page,
      limit,
    });
    preppedURL += `nearby=${nearby}`;
    if (detailed) {
      preppedURL += `&detailed=${detailed}`;
    }
    return preppedURL;
  },

  postAgentContactRequest: () => `${BASE_URL}contact_request/`,

  getAgent: ({ url_path }) => {
    const base = BASE_URL;
    const url = `${base}agent/?secret=${KEY}&url_path=${url_path}`;
    return url;
  },

  getReaAgent: ({ url_path }) => {
    const base = BASE_URL;
    const url = `${base}rea_agent/?secret=${KEY}&url_path=${url_path}`;
    return url;
  },

  getAgentReviews: ({
    agent_slug, page, limit, filter, sort, type,
  }) => {
    const base = BASE_URL;
    let url = `${base}agents/reviews/${agent_slug}?`;
    if (filter) {
      url += `filter=${filter}&`;
    }

    if (sort) {
      url += `sort=${sort}&`;
    }

    if (type) {
      url += `type=${type}&`;
    }
    return prepURL(url, { page, limit });
  },

  cityPageAgents: ({
    page,
    limit,
    city,
    state,
    search,
    city_slug,
    exclude_agents = [],
    unclaimed,
  }) => {
    let url = `${BASE_URL}city/city_page_agents?`;
    url = prepURL(url, {
      city_slug,
      page,
      limit,
      city,
      state,
      search,
    });

    for (let i = 0; i < exclude_agents.length; i++) {
      url += `exclude_agents[]=${exclude_agents[i]}&`;
    }

    if (unclaimed) {
      url += `unclaimed=${unclaimed}&`;
    }

    return url;
  },

  reaFunnelRequests: () => `${BASE_URL}insights/rea_funnel_requests`,

  claimFunnelRequests: () => `${BASE_URL}insights/claim_request`,

  getFunnelCityStats: ({ city, state }) => {
    const base = BASE_URL;
    let url = `${base}stats/funnel_city_stats/?secret=${KEY}&`;
    url = prepURL(url, {
      city,
      state,
    });
    return url;
  },

  getCityNarrative: ({ slug }) => {
    const base = BASE_URL;
    const url = `${base}narrative/city/${slug}?secret=${KEY}`;
    return url;
  },

  getPrivacyPolicy: ({ target, language, countryCode }) => {
    const base = BASE_URL;
    const url = `${base}privacy_policy/${target}/${language}/${countryCode}`;
    return url;
  },

  getTermsOfUse: ({ target, language, countryCode }) => {
    const base = BASE_URL;
    const url = `${base}terms/${target}/${language}/${countryCode}`;
    return url;
  },

  getDoNotCallPolicy: ({ target, language, countryCode }) => {
    const base = BASE_URL;
    const url = `${base}do_not_call_policy/${target}/${language}/${countryCode}`;
    return url;
  },

  googleLocationData: ({ parameters }) => {
    const base = BASE_URL;
    const url = `${base}geo/google/locale?${parameters}`;
    return url;
  },

  claimProfileInterest: ({}) => {
    const base = BASE_URL;
    const url = `${base}claim/interest`;
    return url;
  },

  leadGen: ({}) => {
    const url = `${BASE_URL}agents/lead_gen`;
    return url;
  },

  contactRequest: ({}) => {
    const url = `${BASE_URL}agents/contact_request`;
    return url;
  },

  inlineMessageRequest: ({}) => {
    const url = `${BASE_URL}agents/inline_message_request`;
    return url;
  },

  sendTwoFA: ({}) => {
    const url = `${BASE_URL}agents/send_twofa`;
    return url;
  },

  submitTwoFA: ({}) => {
    const url = `${BASE_URL}agents/submit_twofa`;
    return url;
  },

  checkLightning: ({}) => {
    const url = `${BASE_URL}agents/check_lightning`;
    return url;
  },

  contactUs: ({}) => {
    const url = `${BASE_URL}contact_us`;
    return url;
  },

  sitemapAgents: ({ state, page, limit }) => {
    let url = `${BASE_URL}sitemap/agent_html/${state}?secret=${KEY}&`;

    if (page) {
      url += `page[number]=${page}&`;
    }

    if (limit) {
      url += `page[limit]=${limit}&`;
    }

    return url;
  },

  htmlSitemapStates: () => `${BASE_URL}sitemap/agent_html_states?secret=${KEY}`,

  search: ({ search, agent_limit, city_limit }) => (
    `${BASE_URL
    }search/omni_search?query=${search}&agent_limit=${agent_limit}&city_limit=${city_limit}`
  ),

  terms: (target, language, countryCode) => `${BASE_URL}terms/${target}/${language}/${countryCode}`,

  getAgentReviewUUID: ({ uuid }) => `${BASE_URL}review/uuid/${uuid}`,

  // HTTP Configurations
  GET_CONFIG: ({ token, abortController }) => {
    let headers;
    headers = setupRequestHeaders(false);
    return {
      signal: abortController ? abortController.signal : null,
      method: 'GET',
      headers,
    };
  },
  GET_CONFIG_WITH_BODY: ({ data }) => {
    let headers;
    headers = setupRequestHeaders(false);
    return {
      method: 'GET',
      body: JSON.stringify(data),
      headers,
    };
  },
  POST_FILE_CONFIG: (data) => {
    // authorization token
    const headers = setupRequestHeaders(true);
    return {
      method: 'post',
      body: data,
      headers,
    };
  },
  POST_CONFIG: ({ data, ctx, headers = {} }) => {
    // authorization token
    const headers_req = setupRequestHeaders(false, ctx);
    return {
      method: 'post',
      body: JSON.stringify(data),
      headers: { ...headers_req, ...headers },
    };
  },
  PUT_CONFIG: ({ data, headers = {} }) => {
    // authorization token
    const headers_req = setupRequestHeaders(false);
    return {
      method: 'put',
      body: JSON.stringify(data),
      headers: { ...headers_req, ...headers },
    };
  },
  PATCH_CONFIG: (data) => {
    // authorization token
    const headers = setupRequestHeaders(false);
    return {
      method: 'patch',
      body: JSON.stringify(data),
      headers,
    };
  },
  PUT_FORM_DATA_CONFIG: (formData) => {
    // authorization token
    const headers = setupRequestHeaders(true);
    return {
      method: 'put',
      body: formData,
      headers,
    };
  },
  DELETE_CONFIG: () => {
    // authorization token
    const headers = setupRequestHeaders();
    return {
      method: 'delete',
      headers,
    };
  },
  Subscribe: {
    unsubscribe: ({}) => `${BASE_URL}email/unsubscribe`,
    resubscribe: ({}) => `${BASE_URL}email/resubscribe`,
  },
  Auth: {
    login: ({}) => `${BASE_URL}token/auth/sign_in`,
    logout: ({}) => `${BASE_URL}token/auth/sign_out`,
    validate: ({}) => `${BASE_URL}token/auth/validate_token`,
    checkTrain: ({}) => `${BASE_URL}agent/check_train`,
    lastRequest: ({}) => `${BASE_URL}agent/last_request`,
    acceptTerms: ({}) => `${BASE_URL}agent/accept_terms`,
    CRMToken: ({}) => `${BASE_URL}auth/agentcrm`,
    twoFaLog: ({}) => `${BASE_URL}twofa/log`,
    forgotPassword: ({}) => `${BASE_URL}token/auth/password`,
    CONFIG: (auth) => {
      const headers = setupRequestHeaders(false);
      return {
        method: 'GET',
        headers: {
          ...headers,
          'access-token': auth?.accessToken ?? '',
          'token-type': 'Bearer',
          client: auth?.client ?? '',
          expiry: auth?.expiry ?? '',
          uuid: auth?.uuid ?? '',
        },
      };
    },
    POST_CONFIG: ({ auth, data }) => {
      const headers = setupRequestHeaders(false);
      const payload = {
        method: 'POST',
        headers: {
          ...headers,
          'access-token': auth?.accessToken ?? '',
          'token-type': 'Bearer',
          client: auth?.client ?? '',
          expiry: auth?.expiry ?? '',
          uuid: auth?.uuid ?? '',
        },
        body: JSON.stringify(data),
      };
      return payload;
    },
  },
  SignUp: {
    createAgent: () => `${BASE_URL}agent/sign-up/create-agent`,
    checkAgent: () => `${BASE_URL}agent/sign-up/check-agent`,
    completeAgent: () => `${BASE_URL}agent/sign-up/complete-agent`,
    checkEmail: () => `${BASE_URL}agent/sign-up/check-email`,
    sendTwoFa: () => `${BASE_URL}twofa`,
    consent: () => `${BASE_URL}agent/sign-up/consent`,
    agreement: () => `${BASE_URL}agent/sign-up/agreement`,
  },
  agentZipBoundaries: (url_slug) => `${BASE_URL}agents/zip_boundaries/${url_slug}`,

  agentReaZipBoundaries: (agent_id) => `${BASE_URL}rea_agents/rea_zip_boundaries/${agent_id}`,

  listCities: ({ state }) => `${BASE_URL}geo/list_cities/?state=${state}&secret=${KEY}`,

  cityHtmlSitemap: ({ state }) => `${BASE_URL}sitemap/city_html/${state}?secret=${KEY}`,

  primeFunnel: () => `${BASE_URL}agent/prime_funnel_check`,
  primeFunnelAgentCreated: () => `${BASE_URL}agent/prime_funnel_check/agent_created`,
};

export default API;

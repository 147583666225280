import Link from "next/link";
import MegaMenu from "@referralexchange/rea-ui-react/src/components/navigation/MegaMenu";
import HeadingWithLinks from "@referralexchange/rea-ui-react/src/components/navigation/HeadingWithLinks";
import Content from "@referralexchange/rea-ui-react/src/atoms/Content";
import Button from "@referralexchange/rea-ui-react/src/atoms/Button";

import MainNav from "@referralexchange/rea-ui-react/src/components/navigation/MainNav";

import ShakeHandsIcon from "@referralexchange/rea-ui-react/src/svgs/shake-hands";
import PrimeLogo from "@referralexchange/rea-ui-react/src/svgs/prime-logo-white";
import DocumentMoney from "@referralexchange/rea-ui-react/src/svgs/document-money";
import BookOpenIcon from "@referralexchange/rea-ui-react/src/svgs/book-open";
import BubbleWithQuestionMarkIcon from "@referralexchange/rea-ui-react/src/svgs/bubble-with-question-mark";
import StandoutPersonInAGroupIcon from "@referralexchange/rea-ui-react/src/svgs/nav/standout-person-in-group";

export default function AgentsMegaMenu() {
  const leftCTA = (
    <div className={MegaMenu.styles.cta}>
      <StandoutPersonInAGroupIcon title="Standout in a group icon" />
      <Content className={Content.styles.medium}>
        Become a Partner Agent
      </Content>
      <Content
        className={[Content.styles.body14, Content.styles.center].join(" ")}
      >
        Join the nation's premier agent marketplace for buyers and sellers
      </Content>
      <Button href="/agent/sign-up">Join Today</Button>
    </div>
  );

  const centerItems = [
    <Link href="/for-agents">
      <span
        className={[
          HeadingWithLinks.styles.icon,
          HeadingWithLinks.styles.iconFill,
        ].join(" ")}
      >
        <ShakeHandsIcon />
      </span>
      Partner with Us
    </Link>,
    <Link href="/blog/agent">
      <span className={HeadingWithLinks.styles.icon}>
        <BookOpenIcon />
      </span>
      Agent&apos;s Blog
    </Link>,
    <Link href="/prime">
      <span
        className={[
          HeadingWithLinks.styles.icon,
          HeadingWithLinks.styles.iconFillAndStroke,
        ].join(" ")}
      >
        <PrimeLogo />
      </span>
      Upgrade to PRIME
    </Link>,
    <Link href="/faq/for-agents">
      <span className={HeadingWithLinks.styles.icon}>
        <BubbleWithQuestionMarkIcon />
      </span>
      Agent&apos;s FAQ
    </Link>,
    <Link href="/personal-relocation-network">
      <span className={HeadingWithLinks.styles.icon}>
        <DocumentMoney />
      </span>
      Personal Relocation Network
    </Link>,
  ];
  return (
    <MegaMenu
      label="Agents"
      left={leftCTA}
      center={
        <HeadingWithLinks
          items={centerItems}
          className={MainNav.styles.twoColumn}
        />
      }
    />
  );
}

import Link from "next/link";
import Image from "next/image";
import styles from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import FooterComponent from "@referralexchange/rea-ui-react/src/components/footer/Footer";
import ReaLogo from "@referralexchange/rea-ui-react/src/svgs/rea-logo";

export default function Footer({}) {
  const buyerLinks = {
    heading: "Buyers",
    items: [
      <Link href="/buy" prefetch={false}>
        Find a Buyer's Agent
      </Link>,
      <Link href="/blog/buying/" prefetch={false}>
        Buyer's Blog
      </Link>,
      <Link href="/faq/for-buyers" prefetch={false}>
        Buying a Home FAQ
      </Link>,
      <Link href="/working-with-an-agent" prefetch={false}>
        Working with a Real Estate Agent
      </Link>,
      <Link href="/how-to-find-a-real-estate-agent" prefetch={false}>
        How to Find a Real Estate Agent
      </Link>,
      <Link href="/how-to-compare-real-estate-agents" prefetch={false}>
        How to Compare Real Estate Agents
      </Link>,
      <Link href="/how-a-real-estate-agent-works-for-you" prefetch={false}>
        How a Real Estate Agent Works for You
      </Link>,
      <Link href="/how-to-find-homes-for-sale-near-you" prefetch={false}>
        How to Find Homes for Sale Near You
      </Link>,
      <Link href="/should-you-rent-or-buy-a-home" prefetch={false}>
        Should You Rent or Buy a Home
      </Link>,
    ],
  };

  const sellerLinks = {
    heading: "Sellers",
    items: [
      <Link href="/sell" prefetch={false}>
        Find a Seller's Agent
      </Link>,
      <Link href="/blog/selling/" prefetch={false}>
        Seller's Blog
      </Link>,
      <Link href="/faq/for-sellers" prefetch={false}>
        Selling Your Home FAQ
      </Link>,
      <Link href="/working-with-an-agent" prefetch={false}>
        Working with a Real Estate Agent
      </Link>,
      <Link href="/how-to-find-a-real-estate-agent" prefetch={false}>
        How to Find a Real Estate Agent
      </Link>,
      <Link href="/how-to-compare-real-estate-agents" prefetch={false}>
        How to Compare Real Estate Agents
      </Link>,
      <Link href="/how-a-real-estate-agent-works-for-you" prefetch={false}>
        How a Real Estate Agent Works for You
      </Link>,
      <Link href="/how-to-find-homes-for-sale-near-you" prefetch={false}>
        How to Find Homes for Sale Near You
      </Link>,
    ],
  };

  const workingWithAgentsLinks = {
    heading: "Working with Agents",
    items: [
      <Link href="/real-estate-guides/" prefetch={false}>
        Guides
      </Link>,
      <Link href="/faq/working-with-an-agent" prefetch={false}>
        Working with Agents FAQ
      </Link>,
      <Link href="/agents-directory" prefetch={false}>
        Agent Directory
      </Link>,
      <Link href="/state-directory" prefetch={false}>
        State Directory
      </Link>,
    ],
  };

  const forAgentsLinks = {
    heading: "For Agents",
    items: [
      <Link href="/for-agents" prefetch={false}>
        Partner with Us
      </Link>,
      <Link href="/prime" prefetch={false}>
        Upgrade to PRIME
      </Link>,
      <Link href="/personal-relocation-network" prefetch={false}>
        Personal Relocation Network
      </Link>,
      <Link href="/blog/agent/" prefetch={false}>
        Agent's Blog
      </Link>,
      <Link href="/faq/for-agents" prefetch={false}>
        Agent's FAQ
      </Link>,
    ],
  };
  const aboutLinks = {
    heading: "About",
    items: [
      <Link href="/about" prefetch={false}>
        About Us
      </Link>,
      <Link href="/contact" prefetch={false}>
        Contact
      </Link>,
      <Link href="/blog" prefetch={false}>
        Blog
      </Link>,
      <Link href="/real-estate-guides/" prefetch={false}>
        Guides
      </Link>,
      <Link href="/articles" prefetch={false}>
        Articles
      </Link>,
      <Link href="/reviews" prefetch={false}>
        Reviews
      </Link>,
      <Link href="/how-it-works" prefetch={false}>
        How It Works
      </Link>,
    ],
  };

  const faqLinks = {
    heading: "FAQ",
    items: [
      <Link href="/faq/how-we-work" prefetch={false}>
        How We Work
      </Link>,
      <Link href="/faq" prefetch={false}>
        FAQ page
      </Link>,
    ],
  };

  return (
    <FooterComponent
      logo={
        <Link href="/">
          <ReaLogo />
        </Link>
      }
      phoneNumber={{
        icon: (
          <FontAwesomeIcon
            icon={faPhoneAlt}
            className={styles["Footer__fa-icon"]}
          />
        ),
        label: <a href="tel:+18885862349">(888) 586-2349</a>,
      }}
      address={{
        icon: <FontAwesomeIcon icon={faLocationDot} />,
        label: "PO BOX 3634 Seattle, WA 98124-3634",
      }}
      socials={[
        <a href="https://www.instagram.com/realestateagentsdotcom">
          <FontAwesomeIcon icon={faInstagram} />
        </a>,
        <a href="https://www.facebook.com/realestateagentsdotcom">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>,
      ]}
      accreditations={[
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.shopperapproved.com/reviews/RealEstateAgents.com"
          aria-label="Shopper Approved Profile"
        >
          <Image
            src="/static/images/shopper-logo.png"
            alt="Shopper Approved Logo"
            width={39}
            height={29}
          />
        </a>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.bbb.org/us/ca/san-francisco/profile/marketing-programs/referralexchange-inc-1116-458879"
          aria-label="BBB Profile"
        >
          <Image
            src="/static/images/bbb-rating-a.png"
            alt="BBB Logo"
            width={75}
            height={29}
          />
        </a>,
      ]}
      copyright={`© ${new Date().getFullYear()} RealEstateAgents.com. All rights reserved. DRE License #01426453`}
      lists={[
        buyerLinks,
        sellerLinks,
        workingWithAgentsLinks,
        forAgentsLinks,
        aboutLinks,
        faqLinks,
      ]}
      bottomLinks={[
        <a href="/privacy" target="_blank">
          Privacy
        </a>,
        <a href="/tos" target="_blank">
          Terms
        </a>,
        <a href="/do-not-call" target="_blank">
          Do Not Call Policy
        </a>,
        <a
          href="https://www.referralexchange.com/information"
          target="_blank"
          rel="noreferrer"
        >
          Your Privacy Choices
        </a>,
      ]}
      privacy={
        <a href="/privacy">
          <Image
            src="/static/privacyoptions29x14.png"
            alt="California Privacy Notice"
            width={29}
            height={14}
            fill={false}
          />{" "}
          California Privacy Notice
        </a>
      }
    />
  );
}
